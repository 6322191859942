import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className="sticky top-0 z-50 h-28 flex items-center justify-center bg-white animate-fade-in-up">
    <div className="hidden sm:flex basis-1 grow items-center justify-center font-semibold text-2xl">
      <div className="ml-24">
        menu
      </div>
      <div className="basis-1 grow"></div>
    </div>
    <div className="ml-5 sm:ml-0 mr-2 sm:mr-0">
      <h1>
        <Link to="/" className="text-4xl sm:text-5xl font-semibold">
          {siteTitle}
        </Link>
      </h1>
    </div>
    <div className="basis-1 grow flex items-center justify-center font-semibold text-xl sm:text-2xl">
      <div className="basis-1 grow"></div>
      <div className="mr-5 sm:mr-24">
        contact<br />
        <a href="mailto:bardh@sokoli.co">bardh@sokoli.co</a>
      </div>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
